import React from 'react';

function HireADevIcon() {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-486.000000, -1413.000000)" stroke="#142547"
           strokeWidth="1.39999998">
          <g transform="translate(486.000000, 1413.000000)">
            <rect x="0.699999988" y="0.699999988" width="26.6" height="26.6" rx="13.3"/>
            <rect x="3.69999999" y="3.69999999" width="20.6" height="20.6" rx="10.3"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HireADevIcon;