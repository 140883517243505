import React from 'react';

function LevelUpIcon() {
  return (
    <svg
      width="55px"
      height="38px"
      viewBox="0 0 55 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-169.000000, -2145.000000)" stroke="#3AE1AC"
           strokeWidth="1.6">
          <g transform="translate(170.000000, 2147.000000)">
            <polygon transform="translate(17.000000, 17.000000) rotate(-270.000000) translate(-17.000000, -17.000000) "
                     points="17 0 34 34 0 34"/>
            <polygon transform="translate(35.000000, 17.000000) rotate(-270.000000) translate(-35.000000, -17.000000) "
                     points="35 0 52 34 18 34"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LevelUpIcon;