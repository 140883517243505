import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../App';
import Hero from '../components/Hero';
import ProjectSection from '../components/ProjectSection';
import ContactSection from '../components/ContactSection';
import BackToBusinessSection from '../components/BackToBusinessSection';
import HireMapSection from '../components/HireMapSection';
import BlueDotsIcon from '../assets/icons/BlueDotsIcon';
import TeamExtensionPageHeroImage from '../gatsbyImages/TeamExtensionPageHeroImage';
import NexogenMockupImage from '../gatsbyImages/NexogenMockupImage';
import SaasStoryMockup4Image from '../gatsbyImages/SaasStoryMockup4Image';
// import RecartMockupImage from '../gatsbyImages/RecartMockupImage';
import leHoangGiang from '../assets/images/le-hoang-giang-avatar.png';
// import davidNamenyi from '../assets/images/david-namenyi-avatar.png';
import * as paths from '../paths';
import styles from './pages.module.scss';

const messages = defineMessages({
  title: {
    id: 'teamExtensionPage.title',
    defaultMessage: 'Your Outsourced Dev Team. {br} Our Guarantee.'
  },
  description1: {
    id: 'teamExtensionPage.description1',
    defaultMessage: 'Build a seamlessly scalable tech team '
  },
  description2: {
    id: 'teamExtensionPage.description2',
    defaultMessage:
      '(...and stop worrying about the legal nitty-gritty, stressing about quality control and spending months on the hiring process)'
  },
  nexogenPreTitle: {
    id: 'teamExtensionPage.nexogenPreTitle',
    defaultMessage: 'Web App Development'
  },
  nexogenTitle: {
    id: 'teamExtensionPage.nexogenTitle',
    defaultMessage: 'Nexogen x StreamBright'
  },
  nexogenDescription: {
    id: 'teamExtensionPage.nexogenDescription',
    defaultMessage:
      'Our cooperation with the Streambright team is really smooth - we agree on scope and the team gets it done. We especially value the pro-activity from the developer level: we can count on the honesty and expertise of all the team members from junior to senior level. They raise issues about the scope and recommend solutions to consider. We have the piece of mind that the team only executes on tasks that really thought through and make sense.'
  },
  saasStoryPreTitle: {
    id: 'teamExtensionPage.saasStoryPreTitle',
    defaultMessage: 'Web Development'
  },
  saasStoryTitle: {
    id: 'teamExtensionPage.saasStoryTitle',
    defaultMessage: 'Leading Saas Video Editor x Streambright'
  },
  saasStoryDescription: {
    id: 'teamExtensionPage.saasStoryDescription',
    defaultMessage: `The development services Streambright provided, helped us serve our client base better. As a fast growing startup we receive a ton of feature requests we have to implement to keep everybody happy. With a partner like Streambright we do not have to worry about adding more devs to our team when needed.`
  },
  recartPreTitle: {
    id: 'teamExtensionPage.recartPreTitle',
    defaultMessage: 'Mobile App Development'
  },
  recartTitle: {
    id: 'teamExtensionPage.recartTitle',
    defaultMessage: 'Recart x Streambright'
  },
  recartDescription: {
    id: 'teamExtensionPage.recartDescription',
    defaultMessage:
      'StreamBright’s work with Recart resulted in higher scalability, easier maintenance and a dev environment that makes it easy for anyone to catch up with the latest code in a widget module.'
  },
  button: {
    id: 'teamExtensionPage.button',
    defaultMessage: 'Read case study'
  },
  contactSectionTitle: {
    id: 'teamExtensionPage.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'teamExtensionPage.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  }
});

function TeamExtensionPage() {
  return (
    <App>
      <Helmet>
        <title>Streambright - On-Demand Tech Expertise</title>
        <meta
          name="description"
          content="Build a seamlessly scalable tech team (...and stop worrying about the legal nitty-gritty, stressing about quality control and spending months on the hiring process)"
        />
      </Helmet>
      <Hero
        title={
          <div className={styles.blueText}>
            <FormattedMessage {...messages.title} values={{ br: <br /> }} />
          </div>
        }
        description={
          <div className={styles.textContainer}>
            <FormattedMessage {...messages.description1} />
            <span className={styles.blueText}>
              <FormattedMessage {...messages.description2} />
            </span>
          </div>
        }
        icon={<BlueDotsIcon />}
        backgroundImage={TeamExtensionPageHeroImage}
      />
      <HireMapSection />
      <BackToBusinessSection />
      <ProjectSection
        image={NexogenMockupImage}
        imageClassName={styles.nexogenMockup}
        preTitle={messages.nexogenPreTitle}
        title={messages.nexogenTitle}
        description={messages.nexogenDescription}
        avatar={<img src={leHoangGiang} alt="Le Hoang Giang" />}
        authorName="Le Hoang Giang"
        jobTitle="Co-Founder"
        reverse
        quote
        to={paths.nexogen()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={SaasStoryMockup4Image}
        imageClassName={styles.saasStoryMockup}
        preTitle={messages.saasStoryPreTitle}
        title={messages.saasStoryTitle}
        description={messages.saasStoryDescription}
        jobTitle="CTO & Co-Founder"
        icon={
          <div className={styles.topIcon}>
            <BlueDotsIcon />
          </div>
        }
        quote
        to={paths.saasStory()}
        buttonText={messages.button}
      />
      {/*      <ProjectSection
        image={RecartMockupImage}
        preTitle={messages.recartPreTitle}
        title={messages.recartTitle}
        description={messages.recartDescription}
        avatar={<img src={davidNamenyi} alt="David Namenyi" />}
        authorName="David Namenyi"
        jobTitle="Recart Co-Founder"
        reverse
        quote
        to={paths.recart()}
        buttonText={messages.button}
      /> */}
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default TeamExtensionPage;
