import React from 'react';

function OutsourceATaskIcon() {
  return (
    <svg
      width="64px"
      height="47px"
      viewBox="0 0 64 47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-874.000000, -1401.000000)" stroke="#142547">
          <g transform="translate(874.000000, 1401.000000)">
            <g strokeWidth="1.39999998">
              <rect x="0.699999988" y="0.699999988" width="26.6" height="26.6" rx="13.3" />
              <rect x="3.69999999" y="3.69999999" width="20.6" height="20.6" rx="10.3" />
            </g>
            <g transform="translate(16.000000, 12.000000)" strokeWidth="1.39999998">
              <rect x="0.699999988" y="0.699999988" width="26.6" height="26.6" />
              <rect x="3.69999999" y="3.69999999" width="20.6" height="20.6" />
            </g>
            <g transform="translate(34.000000, 18.000000)" strokeWidth="1.4">
              <polygon id="Triangle" points="14 0 28 28 0 28" />
              <polygon id="Triangle" points="14 7 23 25 5 25" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OutsourceATaskIcon;