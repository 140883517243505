import React from 'react';

function SimpleLineIcon(props) {
  return (
    <svg
      width="2px"
      height="125px"
      viewBox="0 0 2 125"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g transform="translate(-462.000000, -930.000000)" stroke="transparent">
          <path d="M463,931 L463,1053.56717" />
        </g>
      </g>
    </svg>
  );
}

export default SimpleLineIcon;
