import React from 'react';

function ChooseYourCharacterIcon() {
  return (
    <svg
      width="49px"
      height="49px"
      viewBox="0 0 49 49"
      xmlns="http://www.w3.org/2000/svg"
         >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-388.000000, -1120.000000)" stroke="#142547"
           strokeWidth="1.39999998">
          <g transform="translate(388.000000, 1120.000000)">
            <g>
              <rect x="0.699999988" y="0.699999988" width="30.6" height="30.6"/>
              <rect x="4.69999999" y="4.69999999" width="22.6" height="22.6"/>
            </g>
            <g transform="translate(13.000000, 13.000000)">
              <rect x="0.699999988" y="0.699999988" width="34.6" height="34.6" rx="17.3"/>
              <rect x="4.69999999" y="4.69999999" width="26.6" height="26.6" rx="13.3"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ChooseYourCharacterIcon;