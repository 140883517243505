import React from 'react';

function TimeOutsIcon() {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-171.000000, -2388.000000)" stroke="#3AE1AC"
           strokeWidth="1.4">
          <g transform="translate(172.000000, 2389.000000)">
            <circle cx="20" cy="20" r="20" />
            <path d="M20,7 L20,21.7731567 L20,7 Z M31.2350104,21.7731567 L20,21.7731567 L31.2350104,21.7731567 Z"
                  strokeLinecap="square" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TimeOutsIcon;