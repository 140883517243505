import React from 'react';

function ForkedLineIcon(props) {
  return (
    <svg
      width="503px"
      height="128px"
      viewBox="0 0 503 128"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          transform="translate(-462.000000, -1238.000000)"
          stroke="transparent"
        >
          <g transform="translate(462.000000, 1239.000000)">
            <path
              d="M89,81 L89,125.574885"
              style={{ animationDelay: '0.5s' }}
            />
            <path d="M1,0 L1,78.6242086 L1,80.5671739 L502,80.5671739 L502,125.574885" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ForkedLineIcon;
