import React from 'react';

function DevIcon() {
return (
  <svg
    width="61px"
    height="30px"
    viewBox="0 0 61 30"
    xmlns="http://www.w3.org/2000/svg"
  >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-780.000000, -2149.000000)" stroke="#3AE1AC" strokeWidth="1.4">
              <g transform="translate(782.000000, 2149.000000)">
                  <polyline points="38 6 57 15.258315 38 25"/>
                  <polyline transform="translate(9.500000, 15.500000) scale(-1, 1) translate(-9.500000, -15.500000) " points="0 6 19 15.258315 0 25"/>
                  <path d="M33.9133663,0.278637771 L24.8127063,29.6052632" />
              </g>
          </g>
      </g>
  </svg>
);
}

export default DevIcon;