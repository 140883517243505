import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Parallax } from 'react-scroll-parallax';
import cn from 'classnames';
import Link from '../Link';
import { isMessageDefinition } from '../../utils/message';
import QuoteIcon from '../../assets/icons/QuoteIcon';
import styles from './ProjectSection.module.scss';

function ProjectSection(props) {
  const {
    title,
    description,
    preTitle,
    image: Image,
    imageClassName,
    avatar,
    authorName,
    jobTitle,
    intl,
    buttonText,
    variant,
    reverse,
    icon,
    quote,
    to
  } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  const renderPreTitle = () =>
    isMessageDefinition(preTitle)
      ? intl.formatMessage(preTitle, preTitle.values)
      : preTitle;

  return (
    <div
      className={cn(styles.container, {
        [styles.reverse]: reverse,
        [styles.colorPrimary]: variant === 'primary',
        [styles.colorSecondary]: variant === 'secondary'
      })}
    >
      <div className={styles.contentLeft}>
        <div
          className={cn(styles.background, {
            [styles.colorPrimary]: variant === 'primary',
            [styles.colorSecondary]: variant === 'secondary'
          })}
        />
        <Parallax
          y={[-10, 10]}
          tagOuter="figure"
          className={styles.parallax}
          styleInner={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Image
            className={cn(styles.image, imageClassName)}
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
        </Parallax>
      </div>
      <div className={styles.contentRight}>
        <div className={styles.textContainer}>
          <div className={styles.preTitle}>{renderPreTitle()}</div>
          <div className={styles.title}>{renderTitle()}</div>
          {!quote && (
            <div className={styles.description}>{renderDescription()}</div>
          )}
          {quote && (
            <div className={styles.quoteContainer}>
              <div className={styles.quoteIconLeft}>
                <QuoteIcon />
              </div>
              <div className={styles.descriptionContainer}>
                <div className={styles.description}>
                  {renderDescription()}
                  <QuoteIcon className={styles.quoteIconRight} />
                </div>
              </div>
            </div>
          )}
          <div className={styles.authorContainer}>
            <div className={styles.avatar}>{avatar}</div>
            <div className={styles.authorTextContainer}>
              <p className={styles.authorName}>{authorName}</p>
              <p className={styles.jobTitle}>{jobTitle}</p>
            </div>
          </div>
          <Link
            className={styles.button}
            variant="outlined"
            color="primary"
            to={to}
          >
            {buttonText}
          </Link>
        </div>
      </div>
      {icon}
    </div>
  );
}

ProjectSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  preTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  image: PropTypes.elementType.isRequired,
  avatar: PropTypes.node,
  authorName: PropTypes.string,
  jobTitle: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  reverse: PropTypes.bool,
  icon: PropTypes.node,
  quote: PropTypes.bool,
  imageClassName: PropTypes.string,
  to: PropTypes.string.isRequired
};

ProjectSection.defaultProps = {
  title: null,
  description: null,
  preTitle: null,
  avatar: null,
  authorName: '',
  jobTitle: '',
  buttonText: null,
  variant: 'primary',
  reverse: false,
  icon: null,
  quote: false,
  imageClassName: undefined
};

export default injectIntl(ProjectSection);
