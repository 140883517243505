import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const TeamExtensionPageHeroImage = props => {
  const data = useStaticQuery(graphql`
    query {
      teamExtensionPageHeroImage: file(
        relativePath: { eq: "team-extension-page-hero.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      fluid={data.teamExtensionPageHeroImage.childImageSharp.fluid}
      {...props}
    />
  );
};

export default TeamExtensionPageHeroImage;
