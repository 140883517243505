import React, { useRef, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import Button from '../Button';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import StartHereIcon from '../../assets/icons/StartHereIcon';
import SimpleLineIcon from '../../assets/icons/SimpleLineIcon';
import ChooseYourCharacterIcon from '../../assets/icons/ChooseYourCharacterIcon';
import ForkedLineIcon from '../../assets/icons/ForkedLineIcon';
import HireADevIcon from '../../assets/icons/HireADevIcon';
import OutsourceATaskIcon from '../../assets/icons/OutsourceATaskIcon';
import styles from './HireMapSection.module.scss';

const messages = defineMessages({
  mainTitle: {
    id: 'hireMapSection.mainTitle',
    defaultMessage: 'How to hire your next {br} on-demand dev professional:'
  },
  title1: {
    id: 'hireMapSection.title1',
    defaultMessage: '1. Start here'
  },
  description1: {
    id: 'hireMapSection.description1',
    defaultMessage:
      'Tell us what you’re working with and your roadmap for growth. We’ll chart a course for building your team and write technical profiles for the teammates you need right now.\n'
  },
  title2: {
    id: 'hireMapSection.title2',
    defaultMessage: '2. Choose Your Character'
  },
  description2: {
    id: 'hireMapSection.description2',
    defaultMessage:
      'You can either hire the dev(s) that your team is missing or outsource an entire development task to our team.'
  },
  titleA: {
    id: 'hireMapSection.titleA',
    defaultMessage: 'A. Hire a dev'
  },
  descriptionA: {
    id: 'hireMapSection.descriptionA',
    defaultMessage:
      'Review a set of CVs curated by our talent experts and we’ll set up interviews with your top choices.'
  },
  titleB: {
    id: 'hireMapSection.titleB',
    defaultMessage: 'B. Outsource a task'
  },
  descriptionB: {
    id: 'hireMapSection.descriptionB',
    defaultMessage:
      'Accelerate your product development. Get a flawlessly managed project and engineering excellence at a competitive rate.'
  },
  button: {
    id: 'hireMapSection.button',
    defaultMessage: 'Get tech talent on-demand'
  },
  letsTalkButton: {
    id: 'hireMapSection.letsTalkButton',
    defaultMessage: 'Let’s talk'
  }
});

function HireMapSection() {
  const [position, setPosition] = useState(10000000);
  const containerRef = useRef();
  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;
    const { top } = containerRef.current.getBoundingClientRect();
    const bottom = top - window.innerHeight;
    if (bottom >= position) return;

    setPosition(bottom);
  }, [position, setPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.mainTitle}>
        <FormattedMessage {...messages.mainTitle} values={{ br: <br /> }} />
      </div>
      <Container className={styles.contentContainer}>
        <div className={styles.blockContainer}>
          <div>
            <div
              className={cn(styles.icon, { [styles.inView]: position < -400 })}
            >
              <StartHereIcon />
            </div>
            <div>
              <div className={styles.title}>
                <FormattedMessage {...messages.title1} />
              </div>
              <div className={styles.description}>
                <FormattedMessage {...messages.description1} />
              </div>
              <div className={styles.button}>
                <a href="#Contact">
                  <Button variant="contained" color="primary">
                    <FormattedMessage {...messages.button} />
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.simpleLineIcon}>
            <SimpleLineIcon
              className={cn(styles.path, { [styles.inView]: position < -550 })}
            />
          </div>
          <div>
            <div
              className={cn(styles.icon, { [styles.inView]: position < -700 })}
            >
              <ChooseYourCharacterIcon />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>
                <FormattedMessage {...messages.title2} />
              </div>
              <div className={styles.description}>
                <FormattedMessage {...messages.description2} />
              </div>
            </div>
          </div>
          <div className={styles.forkedLineIcon}>
            <ForkedLineIcon
              className={cn(styles.path, { [styles.inView]: position < -850 })}
            />
          </div>
          <a href="#Contact" className={styles.characterBlockContainer}>
            <div className={styles.characterBlockContent}>
              <div className={styles.characterBlockLeft}>
                <div
                  className={cn(styles.characterBlockIcon, {
                    [styles.inView]: position < -850
                  })}
                >
                  <HireADevIcon />
                </div>
                <div>
                  <div className={styles.characterBlockTitle}>
                    <FormattedMessage {...messages.titleA} />
                  </div>
                  <div className={styles.characterBlockDescription}>
                    <FormattedMessage {...messages.descriptionA} />
                  </div>
                </div>
              </div>
              <div className={styles.characterBlockRight}>
                <div
                  className={cn(styles.characterBlockIcon, {
                    [styles.inView]: position < -850
                  })}
                >
                  <OutsourceATaskIcon />
                </div>
                <div>
                  <div className={styles.characterBlockTitle}>
                    <FormattedMessage {...messages.titleB} />
                  </div>
                  <div className={styles.characterBlockDescription}>
                    <FormattedMessage {...messages.descriptionB} />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </Container>
      <div className={styles.letsTalkButton}>
        <a href="#Contact">
          <Button variant="contained" color="primary">
            <FormattedMessage {...messages.letsTalkButton} />
          </Button>
        </a>
      </div>
      <div className={styles.greenIconLeft}>
        <GreenSquareIcon />
      </div>
      <div className={styles.greenIconRight}>
        <GreenSquareIcon />
      </div>
    </div>
  );
}

export default HireMapSection;
