import React from 'react';

function StartHereIcon() {
  return (
    <svg
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
         >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-384.000000, -815.000000)" stroke="#142547"
           strokeWidth="1.39999998">
          <g transform="translate(384.000000, 815.000000)">
            <rect x="0.699999988" y="0.699999988" width="54.6" height="54.6"/>
            <rect x="6.69999999" y="6.69999999" width="42.6" height="42.6"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StartHereIcon;