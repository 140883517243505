import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import Link from '../Link';
import LevelUpIcon from '../../assets/icons/LevelUpIcon';
import DevIcon from '../../assets/icons/DevIcon';
import TimeOutsIcon from '../../assets/icons/TimeOutsIcon';
import NixTechDebtIcon from '../../assets/icons/NixTechDebtIcon';
import * as paths from '../../paths';
import styles from './BackToBusinessSection.module.scss';

const messages = defineMessages({
  mainTitle: {
    id: 'BackToBusinessSection.mainTitle',
    defaultMessage: 'Now you can get back to business'
  },
  firstBoxTitle: {
    id: 'BackToBusinessSection.firstBoxTitle',
    defaultMessage: 'Level Up'
  },
  firstBoxDescription: {
    id: 'BackToBusinessSection.firstBoxDescription',
    defaultMessage:
      'Your on-demand team grows together with your needs and resources. From developers to development, our StreamBright network is here to fill in the gaps in your growing business.'
  },
  secondBoxTitle: {
    id: 'BackToBusinessSection.secondBoxTitle',
    defaultMessage: '+10'
  },
  secondBoxDescription: {
    id: 'BackToBusinessSection.secondBoxDescription',
    defaultMessage:
      'Every developer that you hire has access to our knowledge base — an entire agency’s worth of know-how. With even one hire you can consider all of your technical questions answered.'
  },
  thirdBoxTitle: {
    id: 'BackToBusinessSection.thirdBoxTitle',
    defaultMessage: 'No time-outs'
  },
  thirdBoxDescription: {
    id: 'BackToBusinessSection.thirdBoxDescription',
    defaultMessage:
      'Sometimes the unexpected does happen and it’s best to be prepared. If you need a replacement, we source one for you and facilitate a smooth handover.'
  },
  fourthBoxTitle: {
    id: 'BackToBusinessSection.fourthBoxTitle',
    defaultMessage: 'Nix Tech Debt'
  },
  fourthBoxDescription: {
    id: 'BackToBusinessSection.fourthBoxDescription',
    defaultMessage:
      'Restructure your stack so that your product works like a well-oiled machine. Do it without sidetracking your dev resources and delaying new feature developments.'
  },
  letsTalkButton: {
    id: 'hireMapSection.letsTalkButton',
    defaultMessage: 'Let’s talk with us'
  }
});

function BackToBusinessSection() {
  return (
    <div className={styles.container}>
      <div className={styles.mainTitle}>
        <FormattedMessage {...messages.mainTitle} />
      </div>
      <Container className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <LevelUpIcon />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.firstBoxTitle} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.firstBoxDescription} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.icon}>
            <DevIcon />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.secondBoxTitle} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.secondBoxDescription} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.icon}>
            <TimeOutsIcon />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.thirdBoxTitle} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.thirdBoxDescription} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.icon}>
            <NixTechDebtIcon />
          </div>
          <div className={styles.title}>
            <FormattedMessage {...messages.fourthBoxTitle} />
          </div>
          <div className={styles.description}>
            <FormattedMessage {...messages.fourthBoxDescription} />
          </div>
        </div>
      </Container>
      <div className={styles.letsTalkButton}>
        <Link variant="contained" color="primary" to={paths.teamPage()}>
          <FormattedMessage {...messages.letsTalkButton} />
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(BackToBusinessSection);
